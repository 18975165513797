'use client'
import { styled } from "../config";
import React from "react";

const Svg = React.forwardRef((props, forwardRef: any) => {
  return (
    <svg
      width="13"
      height="13"
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={forwardRef}
    >
      <path
        d="M6.5 0.40625C3.13467 0.40625 0.40625 3.13467 0.40625 6.5C0.40625 9.86533 3.13467 12.5938 6.5 12.5938C9.86533 12.5938 12.5938 9.86533 12.5938 6.5C12.5938 3.13467 9.86533 0.40625 6.5 0.40625ZM9.3125 7.4375H6.5C6.37568 7.4375 6.25645 7.38811 6.16854 7.30021C6.08064 7.2123 6.03125 7.09307 6.03125 6.96875V2.75C6.03125 2.62568 6.08064 2.50645 6.16854 2.41854C6.25645 2.33064 6.37568 2.28125 6.5 2.28125C6.62432 2.28125 6.74355 2.33064 6.83146 2.41854C6.91936 2.50645 6.96875 2.62568 6.96875 2.75V6.5H9.3125C9.43682 6.5 9.55605 6.54939 9.64396 6.63729C9.73186 6.7252 9.78125 6.84443 9.78125 6.96875C9.78125 7.09307 9.73186 7.2123 9.64396 7.30021C9.55605 7.38811 9.43682 7.4375 9.3125 7.4375Z"
        fill="#505458"
      />
    </svg>
  );
});

export const Clock2 = styled(Svg, { fill: "#505458" });
