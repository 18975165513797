'use client'
import { styled } from '../config'

function Svg(props) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <circle
        cx="8"
        cy="8"
        r="7.11111"
        fillOpacity="0.05"
        strokeWidth="1.77778"
        strokeDasharray="2.67 2.67"
      />
    </svg>
  )
}

export const CircleEmpty = styled(Svg, {
  stroke: '#E8AC11',
  fill: '#E8AC11',
})
