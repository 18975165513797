'use client'
export type DislikeIconProps = {
  fillColor?: string
}

export function DislikeIcon({ fillColor = '#8D8F92' }: DislikeIconProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={18} height={17} fill="none">
      <path
        fill={fillColor}
        fillRule="evenodd"
        d="M10.83 15.413c-.28.564-.855.92-1.484.92A2.498 2.498 0 0 1 6.88 13.45l.329-2.115H3.992A3.33 3.33 0 0 1 .698 7.55l.566-4.166A3.327 3.327 0 0 1 4.558.5h11.109c.92 0 1.666.746 1.666 1.667V8c0 .92-.746 1.667-1.666 1.667h-1.984l-2.852 5.746ZM14 8h1.667V2.167H14V8Z"
        clipRule="evenodd"
      />
    </svg>
  )
}
