'use client'
type LikeIconProps = {
  fillColor?: string
}

export function LikeIcon({ fillColor = '#8D8F92' }: LikeIconProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={18} height={17} fill="none">
      <path
        fill={fillColor}
        fillRule="evenodd"
        d="M8.167.667a.833.833 0 0 0-.746.46L4.318 7.333H2.333C1.413 7.333.667 8.08.667 9v5.833c0 .92.746 1.667 1.666 1.667h11.151a3.333 3.333 0 0 0 3.303-2.883l.568-4.167a3.333 3.333 0 0 0-3.302-3.783H10.82l.33-2.115A2.5 2.5 0 0 0 8.68.667h-.513ZM4 14.833V9H2.333v5.833H4Z"
        clipRule="evenodd"
      />
    </svg>
  )
}
