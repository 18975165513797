'use client'
import { styled } from '../config'
import React, { forwardRef } from 'react'

export const ChevronOrderUp = forwardRef<
  SVGSVGElement,
  React.SVGProps<SVGSVGElement>
>(function Impl(props, forwardRef) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="7"
      height="4"
      viewBox="0 0 7 4"
      {...props}
      fill="#1B2026">
      <path d="M0 4L3.5 0L7 4H0Z" fill="#parent" />
    </svg>
  )
})
