import {forwardRef, SVGProps} from 'react'

export const UnlinkBackground = forwardRef<
  SVGSVGElement,
  SVGProps<SVGSVGElement>
>(function Impl(props, forwardRef: any) {
  return (
    <svg
      ref={forwardRef}
      width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect width="26" height="26" rx="4" fill="black" fillOpacity="0.1"/>
      <g opacity="0.6">
        <path
          d="M16.3333 9.75556H13.5778V11.0644H16.3333C17.5113 11.0644 18.4689 12.022 18.4689 13.2C18.4689 14.1851 17.7938 15.0118 16.8776 15.2529L17.8833 16.2587C19.0062 15.6869 19.7778 14.5433 19.7778 13.2C19.7778 11.2987 18.2347 9.75556 16.3333 9.75556ZM15.6444 12.5111H14.1358L15.5136 13.8889H15.6444V12.5111ZM6 7.87489L8.14244 10.0173C6.88867 10.5271 6 11.7602 6 13.2C6 15.1013 7.54311 16.6444 9.44444 16.6444H12.2V15.3356H9.44444C8.26644 15.3356 7.30889 14.378 7.30889 13.2C7.30889 12.1047 8.14244 11.2022 9.21022 11.0851L10.6362 12.5111H10.1333V13.8889H12.014L13.5778 15.4527V16.6444H14.7696L17.532 19.4L18.4 18.532L6.87489 7L6 7.87489Z"
          fill="black"/>
      </g>
    </svg>
  )
})
