import { styled } from '../config'

function Svg(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="11"
      height="15"
      viewBox="0 0 11 15"
      fill="#0866F2"
      {...props}>
      <path d="M10.45 14.9986C10.3155 14.999 10.1855 14.9513 10.0846 14.8647L5.5 10.8944L0.915408 14.8647C0.83607 14.9334 0.73802 14.9782 0.633086 14.9939C0.528153 15.0095 0.420821 14.9952 0.324041 14.9527C0.227262 14.9102 0.14517 14.8414 0.0876716 14.7545C0.0301733 14.6676 -0.000274513 14.5663 1.86494e-06 14.4629V2.14266C0.000638784 1.57458 0.232628 1.02995 0.64507 0.628255C1.05751 0.226563 1.61672 0.000620318 2.2 0H8.8C9.38328 0.000620318 9.94249 0.226563 10.3549 0.628255C10.7674 1.02995 10.9994 1.57458 11 2.14266V14.4629C11 14.605 10.9421 14.7413 10.8389 14.8417C10.7358 14.9422 10.5959 14.9986 10.45 14.9986Z" />
    </svg>
  )
}

export const Favourite = styled(Svg)
