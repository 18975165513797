'use client'
import { styled } from '../config'

export function Svg(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="118"
      height="118"
      viewBox="0 0 118 118"
      fill="none">
      <path
        d="M30.4805 81.3401C31.002 80.5894 31.4828 79.8076 31.8589 78.9593C33.7774 74.6328 38.0899 61.9457 33.1628 43.4009C27.0067 20.2299 49.3192 20.7604 55.5954 23.3724C61.8715 25.9844 74.2111 37.0298 79.6999 37.361C85.1886 37.6922 99.2003 31.2507 96.2174 42.3443C93.2344 53.4379 83.7758 60.883 86.8085 74.5131C89.8411 88.1433 85.507 98.6427 74.6283 93.8869C63.7495 89.1311 56.281 82.7033 46.3122 93.5207C36.6202 104.038 24.3585 90.1529 30.4805 81.3401Z"
        fill="#DADEF9"
      />
      <path
        d="M29.01 52.1933L77.858 42.6793L75.9786 33.0301C75.915 32.7038 75.7878 32.3932 75.6042 32.116C75.4206 31.8388 75.1842 31.6005 74.9085 31.4147C74.6328 31.2289 74.3232 31.0992 73.9974 31.033C73.6716 30.9669 73.3359 30.9656 73.0096 31.0291L28.0713 47.3735L29.01 52.1933Z"
        fill="#5368E5"
      />
      <path
        d="M28.1006 47.5273L81.5202 37.1233C82.2881 36.9737 83.0841 37.1354 83.7328 37.5726C84.3816 38.0099 84.8301 38.687 84.9797 39.455L91.1234 70.9995C91.2729 71.7675 91.1113 72.5634 90.674 73.2122C90.2368 73.861 89.5597 74.3095 88.7917 74.4591L38.2677 84.2991C37.4998 84.4487 36.7038 84.2871 36.0551 83.8498C35.4063 83.4125 34.9577 82.7355 34.8082 81.9675L28.1006 47.5273Z"
        fill="#A8B3F3"
      />
      <path
        d="M67.8119 52.4683L86.4852 48.8314C87.2531 48.6819 88.0491 48.8435 88.6979 49.2808C89.3466 49.718 89.7952 50.3951 89.9447 51.1631L91.3316 58.2839C91.4812 59.0519 91.3195 59.8478 90.8823 60.4966C90.445 61.1454 89.7679 61.5939 88.9999 61.7435L70.3266 65.3803C70.1797 65.4089 70.0274 65.378 69.9032 65.2943C69.7791 65.2107 69.6933 65.0811 69.6647 64.9342L67.3657 53.1302C67.3371 52.9833 67.368 52.831 67.4517 52.7068C67.5354 52.5827 67.6649 52.4969 67.8119 52.4683Z"
        fill="#5368E5"
      />
      <path
        d="M75.1988 60.7414C76.8619 60.7414 78.2101 59.3931 78.2101 57.73C78.2101 56.067 76.8619 54.7188 75.1988 54.7188C73.5357 54.7188 72.1875 56.067 72.1875 57.73C72.1875 59.3931 73.5357 60.7414 75.1988 60.7414Z"
        fill="white"
      />
    </svg>
  )
}

export const Wallet = styled(Svg, {
  stroke: '#DF6E6E',
})
