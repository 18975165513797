'use client'
import { motion } from 'framer-motion'
import React from 'react'

export function ButtonMotion({
  children,
  onClickButton,
}: {
  children: React.ReactNode
  onClickButton?: () => void
}) {
  return (
    <motion.button
      whileTap={{ scale: 0.95 }}
      className={`flex h-[25px] w-[25px] cursor-pointer items-center justify-center rounded-[5px] bg-black bg-opacity-10 text-center text-gray-600 hover:bg-gray-300`}
      onClick={(e) => {
        if (onClickButton) {
          onClickButton()
          e.preventDefault()
        }
      }}>
      {children}
    </motion.button>
  )
}
