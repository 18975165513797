'use client'
import { styled } from '../config'

function Svg(props) {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="#B0ACC1" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20ZM13.75 10.625C14.0952 10.625 14.375 10.3452 14.375 10C14.375 9.65482 14.0952 9.375 13.75 9.375H6.25C5.90482 9.375 5.625 9.65482 5.625 10C5.625 10.3452 5.90482 10.625 6.25 10.625H13.75Z"
        fill="parent"
      />
    </svg>
  )
}

export const SubtractButton = styled(Svg)
