'use client'
import { styled } from "../config";

function Svg(props) {
  return (
    <svg
      width="10"
      height="2"
      viewBox="0 0 10 2"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M1 1L9 1" stroke="white" strokeWidth="2" strokeLinecap="round" />
    </svg>
  );
}

export const PartialCheck = styled(Svg);
