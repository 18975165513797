'use client'
export function Image(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      {...props}
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.5833 7.5C14.5833 8.65059 13.6506 9.58333 12.5 9.58333C11.3494 9.58333 10.4167 8.65059 10.4167 7.5C10.4167 6.34941 11.3494 5.41667 12.5 5.41667C13.6506 5.41667 14.5833 6.34941 14.5833 7.5Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.83333 2.5H14.1583C16 2.5 17.4917 3.99167 17.4917 5.83333V14.1667C17.4917 16.0083 16 17.5 14.1583 17.5H5.83333C3.99167 17.5 2.5 16.0083 2.5 14.1667V5.83333C2.5 3.99167 3.99167 2.5 5.83333 2.5ZM14.1667 4.16667H5.83333V4.175C4.91667 4.175 4.16667 4.925 4.16667 5.84167V10.1833L5.225 9.39167L5.24167 9.375C6.25833 8.69167 7.625 8.85 8.45833 9.75C9.68333 11.0667 10.9083 12.0417 12.5 12.0417C13.9167 12.0417 14.8833 11.575 15.8333 10.6917V5.83333C15.8333 4.91667 15.0833 4.16667 14.1667 4.16667Z"
        fill="currentColor"
      />
    </svg>
  )
}
