'use client'
export function Bell(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="21"
      viewBox="0 0 19 21"
      fill="none"
      {...props}>
      <path
        d="M11.1631 16.9236L10.887 17.6158C10.594 18.35 10.0214 18.9378 9.29505 19.2499C8.56871 19.5619 7.74816 19.5726 7.01391 19.2797C6.27966 18.9867 5.69185 18.4141 5.3798 17.6878C5.06776 16.9614 5.05703 16.1409 5.34998 15.4066L5.62612 14.7145M16.3832 17.3732C15.8148 15.5704 15.3067 14.5653 16.8022 10.8171C18.1716 7.38455 16.9067 5.46233 15.7011 4.29282C15.5408 4.13764 15.4326 3.88485 15.4527 3.66473C15.5433 2.7025 15.1366 1.66072 14.1936 1.28447C13.2506 0.908232 12.2378 1.38419 11.6435 2.14595C11.5058 2.32164 11.2544 2.42746 11.0314 2.42973C9.34971 2.44825 7.11246 2.96819 5.74159 6.40415C4.24395 10.1516 3.18355 10.5308 1.53025 11.4472C0.845274 11.8268 0.911026 12.8333 1.71605 13.1545L15.0775 18.4854C15.8782 18.8048 16.6172 18.1164 16.3832 17.3732Z"
        stroke="black"
        strokeWidth="1.29383"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
