import {styled} from '../config'

function Svg(props) {
  return (
    <svg width="13" height="11" viewBox="0 0 13 11" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M1.81848 3.86362V2.02272C1.81848 1.75148 1.92623 1.49135 2.11803 1.29955C2.30983 1.10775 2.56996 1 2.8412 1H4.78156C4.9835 1.00001 5.18091 1.05979 5.34892 1.17182L6.06073 1.64636C6.22874 1.75839 6.42615 1.81817 6.62809 1.81818H10.6139C10.8851 1.81818 11.1453 1.92593 11.3371 2.11773C11.5289 2.30952 11.6366 2.56966 11.6366 2.8409V3.86362"
        stroke="black" strokeWidth="0.818178" strokeLinecap="round" strokeLinejoin="round"/>
      <path
        d="M12.452 4.74763L12.0373 8.97787C12.0373 9.2488 11.9298 9.50866 11.7383 9.70041C11.5469 9.89216 11.2873 10.0001 11.0163 10.0006H2.43825C2.16732 10.0001 1.90764 9.89216 1.71623 9.70041C1.52482 9.50866 1.41732 9.2488 1.41732 8.97787L1.0026 4.74763C0.993602 4.63505 1.00801 4.52182 1.04491 4.41508C1.08182 4.30834 1.14043 4.21039 1.21704 4.12741C1.29366 4.04443 1.38663 3.97821 1.49009 3.93292C1.59356 3.88763 1.70528 3.86425 1.81822 3.86426H11.6389C11.7516 3.8646 11.8631 3.88824 11.9662 3.93369C12.0694 3.97913 12.1621 4.0454 12.2384 4.12835C12.3147 4.21129 12.3731 4.30911 12.4099 4.41568C12.4466 4.52225 12.461 4.63526 12.452 4.74763Z"
        stroke="black" strokeWidth="0.818178" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export const Folder = styled(Svg)
