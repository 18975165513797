'use client'
import { styled } from "../config";

function Svg(props) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0.750488 3.00049V7.50049H5.25049"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.2495 14.9995V10.4995H12.7495"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.368 6.75018C14.9876 5.67527 14.3411 4.71423 13.4889 3.95674C12.6366 3.19925 11.6064 2.67 10.4943 2.41837C9.38216 2.16674 8.22442 2.20093 7.1291 2.51776C6.03377 2.83458 5.03655 3.42371 4.23049 4.23018L0.750488 7.50018M17.2505 10.5002L13.7705 13.7702C12.9644 14.5766 11.9672 15.1658 10.8719 15.4826C9.77655 15.7994 8.61882 15.8336 7.5067 15.582C6.39459 15.3304 5.36434 14.8011 4.51209 14.0436C3.65984 13.2861 3.01336 12.3251 2.63299 11.2502"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export const Refresh = styled(Svg, {
  stroke: "#1B2026",
});
