'use client'
import { styled } from '../config'

function SvgFlag(props) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="#858585"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M2.8125 16.875C2.66332 16.875 2.52024 16.8157 2.41475 16.7102C2.30926 16.6048 2.25 16.4617 2.25 16.3125V2.3952C2.25004 2.24781 2.28868 2.10301 2.36208 1.97521C2.43548 1.84741 2.54108 1.74106 2.66836 1.66676C3.09375 1.41961 3.95086 1.125 5.625 1.125C6.93316 1.125 8.39637 1.64215 9.6873 2.09812C10.7269 2.46551 11.7088 2.8125 12.375 2.8125C13.2326 2.80987 14.0811 2.63658 14.8711 2.30273C14.9673 2.26213 15.072 2.24596 15.176 2.25566C15.28 2.26537 15.3799 2.30065 15.4669 2.35836C15.554 2.41607 15.6253 2.49442 15.6747 2.58642C15.7241 2.67842 15.75 2.78121 15.75 2.88563V10.5975C15.7499 10.7342 15.7099 10.8679 15.635 10.9822C15.56 11.0965 15.4534 11.1865 15.3281 11.2412C15.0219 11.3752 13.9039 11.8125 12.375 11.8125C11.5263 11.8125 10.4632 11.5615 9.33785 11.2954C8.07293 10.9965 6.76512 10.6875 5.625 10.6875C4.32879 10.6875 3.66539 10.8837 3.375 11.0078V16.3125C3.375 16.4617 3.31574 16.6048 3.21025 16.7102C3.10476 16.8157 2.96168 16.875 2.8125 16.875Z"
        fill="parent"
      />
    </svg>
  )
}

export const Flag = styled(SvgFlag, {})
