'use client'
import { styled } from "../config";

function Svg(props) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2.06562 11.1562C1.96911 11.1563 1.87464 11.1284 1.79363 11.076C1.71261 11.0235 1.64851 10.9487 1.60906 10.8606C1.13171 9.79452 0.92873 8.62591 1.01863 7.46128C1.10852 6.29666 1.48843 5.17304 2.12374 4.19282C2.75904 3.2126 3.62955 2.40695 4.65592 1.84928C5.68229 1.2916 6.83191 0.999644 8 1C8.13261 1 8.25978 1.05268 8.35355 1.14645C8.44732 1.24022 8.5 1.36739 8.5 1.5V8C8.50002 8.09655 8.47208 8.19104 8.41957 8.27206C8.36705 8.35308 8.2922 8.41716 8.20406 8.45656L2.26969 11.1112C2.20561 11.1405 2.13606 11.1559 2.06562 11.1562Z"
        fill="#C0C0C0"
      />
      <path
        d="M9.79969 2.1306C9.76344 2.12325 9.726 2.12403 9.69009 2.1329C9.65418 2.14176 9.62068 2.15848 9.59201 2.18186C9.56334 2.20524 9.54022 2.23468 9.52431 2.26808C9.5084 2.30147 9.5001 2.33798 9.5 2.37497V7.99997C9.49975 8.28947 9.41585 8.57274 9.2584 8.81568C9.10095 9.05862 8.87666 9.25089 8.6125 9.36935L3.22281 11.7812C3.18941 11.7961 3.15968 11.8182 3.13573 11.8459C3.11178 11.8735 3.09419 11.9061 3.08421 11.9413C3.07423 11.9765 3.07209 12.0134 3.07795 12.0496C3.08381 12.0857 3.09752 12.1201 3.11813 12.1503C3.5306 12.7569 4.0426 13.2895 4.6325 13.7256C5.75015 14.557 7.10702 15.0041 8.5 15C12.0841 15 15 12.084 15 8.49997C15 5.36091 12.7631 2.73435 9.79969 2.1306Z"
        fill="#C0C0C0"
      />
    </svg>
  );
}

export const ChartPie = styled(Svg, {});
