import { SVGProps } from 'react'

interface PlatformShortProps extends SVGProps<SVGSVGElement> {}

const Platforms = {
  Policía: '#36A9E1',
  Bombero: '#D02E28',
}

const PlatformsBg = {
  Bombero: '#0E3266',
  Policía: '#2D2E83',
  Correos: '#ffda00',
  Administrativo: '#f88333',
  Default: '#D02E28',
}

export function PlatformShort({ ...props }: PlatformShortProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={32}
      height={32}
      fill="none"
      {...props}>
      <circle cx={16} cy={16} r={16} fill={PlatformsBg.Default} />
      <path
        fill={'#fff'}
        d="M26.762 23h-3.66c0-4.089-.723-6.81-2.214-8.319-2.005-2.034-5.525-1.943-9.597-1.845l-.87.022V23h-3.66V9.087h1.831c.865 0 1.752-.022 2.61-.044 4.488-.114 9.13-.232 12.243 2.921 2.232 2.26 3.317 5.87 3.317 11.036Z"
      />
    </svg>
  )
}
