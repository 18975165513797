'use client'
import { SVGProps } from 'react'

export function InfoFill(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.00008 1.16666C3.77842 1.16666 1.16675 3.77833 1.16675 6.99999C1.16675 10.2217 3.77842 12.8333 7.00008 12.8333C10.2217 12.8333 12.8334 10.2217 12.8334 6.99999C12.8334 3.77833 10.2217 1.16666 7.00008 1.16666ZM5.83342 6.41666C5.83342 6.09449 6.09458 5.83332 6.41675 5.83332H7.00008C7.32225 5.83332 7.58342 6.09449 7.58342 6.41666V9.33332C7.58342 9.65549 7.32225 9.91666 7.00008 9.91666C6.67792 9.91666 6.41675 9.65549 6.41675 9.33332V6.99999C6.09458 6.99999 5.83342 6.73882 5.83342 6.41666ZM7.00008 4.08332C6.67792 4.08332 6.41675 4.34449 6.41675 4.66666C6.41675 4.98882 6.67792 5.24999 7.00008 5.24999C7.32225 5.24999 7.58342 4.98882 7.58342 4.66666C7.58342 4.34449 7.32225 4.08332 7.00008 4.08332Z"
        fill="#E32D3F"
      />
    </svg>
  )
}
