'use client'

export function CloseModal(props) {
  return (
    <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M2 2L9 8.67947L2 15.3589" stroke="black" strokeWidth="2.33333" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M9 2L16 8.67947L9 15.3589" stroke="black" strokeWidth="2.33333" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>

  )
}
