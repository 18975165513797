'use client'

export function CrossFill(props) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="#28B8F5"
      {...props}
      xmlns="http://www.w3.org/2000/svg">
      <circle cx="8" cy="8" r="8" fill="#parent" />
      <path
        d="M11 5L5 11"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5 5L11 11"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
