import {styled} from '../config'

function Svg(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="9" height="12" viewBox="0 0 9 12" fill="none" {...props}>
      <path d="M8.90031 4.79198H5.40615C5.08843 4.79198 4.78371 4.66576 4.55904 4.4411C4.33438 4.21643 4.20816 3.91171 4.20816 3.59398V0.0998329C4.20816 0.0733556 4.19764 0.0479627 4.17892 0.0292404C4.1602 0.0105181 4.1348 0 4.10833 0H1.81217C1.38853 0 0.982247 0.168289 0.68269 0.467846C0.383133 0.767403 0.214844 1.17369 0.214844 1.59733V9.58396C0.214844 10.0076 0.383133 10.4139 0.68269 10.7134C0.982247 11.013 1.38853 11.1813 1.81217 11.1813H7.40281C7.82645 11.1813 8.23274 11.013 8.53229 10.7134C8.83185 10.4139 9.00014 10.0076 9.00014 9.58396V4.89181C9.00014 4.86533 8.98962 4.83994 8.9709 4.82122C8.95218 4.8025 8.92678 4.79198 8.90031 4.79198Z" fill="black"/>
      <path d="M8.6807 3.90845L5.09146 0.319203C5.08448 0.312265 5.0756 0.307544 5.06594 0.305635C5.05629 0.303726 5.04628 0.304715 5.03719 0.308475C5.02809 0.312236 5.02031 0.318602 5.01483 0.326772C5.00934 0.334942 5.00639 0.344552 5.00635 0.354394V3.59422C5.00635 3.70013 5.04842 3.8017 5.12331 3.87659C5.1982 3.95148 5.29977 3.99355 5.40568 3.99355H8.64551C8.65535 3.99351 8.66496 3.99056 8.67313 3.98507C8.6813 3.97959 8.68767 3.97181 8.69143 3.96271C8.69519 3.95362 8.69618 3.94361 8.69427 3.93396C8.69236 3.9243 8.68764 3.91543 8.6807 3.90845Z" fill="black"/>
    </svg>
  )
}

export const File = styled(Svg)
