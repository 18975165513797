'use client'
export function Edit(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      {...props}>
      <path
        d="M11.2069 4.04001L2.70281 12.565L2.1875 13.8125L3.435 13.2972L11.96 4.79313L11.2069 4.04001ZM12.9084 2.33876L12.54 2.70688L13.2931 3.46001L13.6616 3.09157C13.7582 2.99482 13.8125 2.86365 13.8125 2.72688C13.8125 2.59011 13.7582 2.45894 13.6616 2.3622L13.6381 2.33876C13.5902 2.29084 13.5333 2.25283 13.4707 2.2269C13.4081 2.20097 13.341 2.18762 13.2733 2.18762C13.2055 2.18762 13.1384 2.20097 13.0758 2.2269C13.0132 2.25283 12.9563 2.29084 12.9084 2.33876Z"
        stroke="#5C5C5C"
        strokeWidth="1.375"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
