import * as React from 'react'
import { styled } from '../config'
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={1113}
    height={748}
    fill="none"
    {...props}>
    <path
      stroke="url(#a)"
      strokeWidth={4}
      d="M1110.27 745.964H907.505c0-217.29-40.112-361.906-122.698-442.051-111.147-108.096-306.192-103.29-531.847-98.065l-48.144 1.149v538.967H2V6.635h101.434c47.935 0 97.077-1.202 144.591-2.351 248.703-6.06 505.911-12.33 678.433 155.222 123.692 120.113 183.812 311.959 183.812 586.458Z"
      opacity={0.8}
    />
    <defs>
      <linearGradient
        id="a"
        x1={-182.5}
        x2={643.794}
        y1={-1407.5}
        y2={828.851}
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#E30613" />
        <stop offset={1} stopColor="#16172D" />
      </linearGradient>
    </defs>
  </svg>
)
export const NBackground = styled(SvgComponent)
