import {styled} from '../config'

function Svg(props) {
  return (
    <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path opacity="0.4" d="M6.5 1V12M12 6.5H1" stroke="black" strokeWidth="1.22222" strokeLinecap="round"
            strokeLinejoin="round"/>
    </svg>
  )
}

export const Plus = styled(Svg)
