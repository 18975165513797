'use client'

export function RoundDash(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      {...props}>
      <circle
        cx="8"
        cy="8"
        r="7.11111"
        stroke="#9B9B9B"
        strokeWidth="1.77778"
        strokeDasharray="2.67 2.67"
      />
    </svg>
  )
}
