'use client'
export function Card(props) {
  return (
    <svg
      width="20"
      height="13"
      viewBox="0 0 20 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M18.621 0H0.987335C0.442045 0 1.16842e-07 0.442045 2.60973e-07 0.987335L3.1752e-06 12.0127C3.31933e-06 12.558 0.442048 13 0.987338 13H18.621C19.1663 13 19.6083 12.558 19.6083 12.0127L19.6083 0.987335C19.6083 0.442045 19.1663 0 18.621 0Z"
        fill="#AEB7E9"
      />
      <path
        d="M17.8583 1.16797H15.8014C15.4742 1.16797 15.209 1.4332 15.209 1.76037L15.209 3.81727C15.209 4.14445 15.4742 4.40967 15.8014 4.40967H17.8583C18.1855 4.40967 18.4507 4.14445 18.4507 3.81727L18.4507 1.76037C18.4507 1.4332 18.1855 1.16797 17.8583 1.16797Z"
        fill="white"
      />
      <path
        d="M8.37631 7.75H1.53451C1.51491 7.75 1.49902 7.76589 1.49902 7.78548L1.49902 8.79713C1.49902 8.81673 1.51491 8.83261 1.53451 8.83261H8.37631C8.39591 8.83261 8.41179 8.81673 8.41179 8.79713L8.41179 7.78548C8.41179 7.76589 8.39591 7.75 8.37631 7.75Z"
        fill="white"
      />
      <path
        d="M11.2979 9.84375H1.53353C1.51393 9.84375 1.49805 9.85964 1.49805 9.87923L1.49805 10.8909C1.49805 10.9105 1.51394 10.9264 1.53353 10.9264H11.2979C11.3175 10.9264 11.3334 10.9105 11.3334 10.8909L11.3334 9.87923C11.3334 9.85964 11.3175 9.84375 11.2979 9.84375Z"
        fill="white"
      />
    </svg>
  )
}
