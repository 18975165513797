'use client'
import { styled } from '../config'

function CheckSvg(props) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      stroke="white"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M16.25 5L7.5 15L3.75 11.25"
        stroke="parent"
        strokeWidth="3.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export const Check = styled(CheckSvg, {})
