'use client'
import { styled } from '../config'

function Svg(props) {
  return (
    <svg
      width="10"
      height="14"
      viewBox="0 0 10 14"
      fill="#737980"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M8.0625 5.25H7.625V3.0625C7.625 2.36631 7.34844 1.69863 6.85616 1.20634C6.36387 0.714062 5.69619 0.4375 5 0.4375C4.30381 0.4375 3.63613 0.714062 3.14384 1.20634C2.65156 1.69863 2.375 2.36631 2.375 3.0625V5.25H1.9375C1.47353 5.25051 1.0287 5.43504 0.700623 5.76312C0.372544 6.0912 0.188007 6.53603 0.1875 7V11.8125C0.188007 12.2765 0.372544 12.7213 0.700623 13.0494C1.0287 13.3775 1.47353 13.562 1.9375 13.5625H8.0625C8.52647 13.562 8.9713 13.3775 9.29938 13.0494C9.62746 12.7213 9.81199 12.2765 9.8125 11.8125V7C9.81199 6.53603 9.62746 6.0912 9.29938 5.76312C8.9713 5.43504 8.52647 5.25051 8.0625 5.25ZM6.75 5.25H3.25V3.0625C3.25 2.59837 3.43437 2.15325 3.76256 1.82506C4.09075 1.49687 4.53587 1.3125 5 1.3125C5.46413 1.3125 5.90925 1.49687 6.23744 1.82506C6.56563 2.15325 6.75 2.59837 6.75 3.0625V5.25Z"
        fill="#parent"
      />
    </svg>
  )
}

export const Lock = styled(Svg)
