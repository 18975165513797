'use client'
import React, { forwardRef } from 'react'

export const ChevronOrderDown = forwardRef<
  SVGSVGElement,
  React.SVGProps<SVGSVGElement>
>(function Impl(props, forwardRef) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="7"
      height="4"
      viewBox="0 0 7 4"
      fill="#1B2026"
      {...props}
      ref={forwardRef}>
      <g clipPath="url(#clip0_8301_4679)">
        <path
          d="M7 1.22392e-06L3.5 4L6.99382e-07 0L7 1.22392e-06Z"
          fill="#parent"
        />
      </g>
      <defs>
        <clipPath id="clip0_8301_4679">
          <rect width="7" height="4" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
})
