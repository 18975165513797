'use client'
import { styled } from '../config'

function Svg(props) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <g clipPath="url(#clip0_1615_4192)">
        <path
          d="M11.9601 11.9599C10.8205 12.8285 9.43282 13.3098 8.00008 13.3332C3.33341 13.3332 0.666748 7.99985 0.666748 7.99985C1.49601 6.45445 2.64617 5.10426 4.04008 4.03985M6.60008 2.82652C7.05897 2.71911 7.52879 2.66541 8.00008 2.66652C12.6667 2.66652 15.3334 7.99985 15.3334 7.99985C14.9287 8.75693 14.4461 9.46968 13.8934 10.1265M9.41341 9.41319C9.23032 9.60969 9.00951 9.76729 8.76418 9.8766C8.51885 9.98591 8.25402 10.0447 7.98548 10.0494C7.71693 10.0542 7.45019 10.0048 7.20115 9.90418C6.95212 9.80359 6.7259 9.65387 6.53598 9.46396C6.34606 9.27404 6.19634 9.04782 6.09575 8.79878C5.99516 8.54975 5.94577 8.283 5.9505 8.01446C5.95524 7.74592 6.01402 7.48108 6.12333 7.23575C6.23264 6.99042 6.39025 6.76962 6.58675 6.58652"
          stroke="#737980"
          strokeWidth="1.33333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M0.666748 0.666504L15.3334 15.3332"
          stroke="#737980"
          strokeWidth="1.33333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1615_4192">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export const EyeOff = styled(Svg)
