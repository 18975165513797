'use client'
import { SVGProps } from 'react'

export function UnGroup(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.1786 3.81547C10.5277 3.16459 9.47247 3.16459 8.8216 3.81547L8.08937 4.5477C7.76393 4.87314 7.23629 4.87314 6.91085 4.5477C6.58542 4.22226 6.58542 3.69462 6.91085 3.36919L7.64309 2.63696C8.94483 1.33521 11.0554 1.33521 12.3571 2.63695L13.0894 3.36919C13.4148 3.69462 13.4148 4.22226 13.0894 4.5477C12.7639 4.87314 12.2363 4.87314 11.9109 4.5477L11.1786 3.81547ZM4.5477 6.91085C4.87314 7.23629 4.87314 7.76393 4.5477 8.08937L3.81547 8.8216C3.16459 9.47247 3.16459 10.5277 3.81547 11.1786L4.5477 11.9109C4.87314 12.2363 4.87314 12.7639 4.5477 13.0894C4.22226 13.4148 3.69462 13.4148 3.36919 13.0894L2.63695 12.3571C1.33521 11.0554 1.33521 8.94483 2.63696 7.64309L3.36919 6.91085C3.69462 6.58542 4.22226 6.58542 4.5477 6.91085ZM15.4525 6.91085C15.778 6.58542 16.3056 6.58542 16.631 6.91085L17.3633 7.64309C18.665 8.94483 18.665 11.0554 17.3633 12.3571L16.631 13.0894C16.3056 13.4148 15.778 13.4148 15.4525 13.0894C15.1271 12.7639 15.1271 12.2363 15.4525 11.9109L16.1848 11.1786C16.8356 10.5277 16.8356 9.47247 16.1848 8.8216L15.4525 8.08937C15.1271 7.76393 15.1271 7.23629 15.4525 6.91085ZM6.91085 15.4525C7.23629 15.1271 7.76393 15.1271 8.08937 15.4525L8.8216 16.1848C9.47247 16.8356 10.5277 16.8356 11.1786 16.1848L11.9109 15.4525C12.2363 15.1271 12.7639 15.1271 13.0894 15.4525C13.4148 15.778 13.4148 16.3056 13.0894 16.631L12.3571 17.3633C11.0554 18.665 8.94484 18.665 7.64309 17.3633L6.91085 16.631C6.58542 16.3056 6.58542 15.778 6.91085 15.4525Z"
        fill="currentColor"
      />
    </svg>
  )
}
