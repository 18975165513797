'use client'
import React, { FC, forwardRef, SVGProps } from 'react'

export const WrongQuestionsTest = forwardRef<
  SVGSVGElement,
  SVGProps<SVGSVGElement>
>(function WornQuestionTestImpl(props, forwardRef: any) {
  const nextProps = {
    ...props,
    width: props.width || '16',
    height: props.height || '16',
    viewBox: props.viewBox || '0 0 16 16',
  }

  return (
    <svg
      ref={forwardRef}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...nextProps}>
      <path
        d="M5.24016 1.33325H10.7602L14.6668 5.23992V10.7599L10.7602 14.6666H5.24016L1.3335 10.7599V5.23992L5.24016 1.33325Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.86849 6.17731C4.59917 5.90798 4.59918 5.47131 4.86851 5.20199C5.13785 4.93266 5.57451 4.93267 5.84384 5.20201L7.9999 7.35816L10.156 5.20203C10.4253 4.9327 10.862 4.9327 11.1313 5.20202C11.4007 5.47135 11.4007 5.90802 11.1313 6.17735L8.9752 8.3335L11.131 10.4894C11.4003 10.7588 11.4003 11.1954 11.131 11.4647C10.8617 11.7341 10.425 11.7341 10.1557 11.4647L7.99988 9.30884L5.84398 11.4648C5.57465 11.7341 5.13798 11.7341 4.86865 11.4648C4.59932 11.1954 4.59932 10.7588 4.86865 10.4894L7.02458 8.33349L4.86849 6.17731Z"
        fill="white"
      />
    </svg>
  )
})
