'use client'
'use client'
import { motion } from 'framer-motion'

export function AnimatedCheckboxIndicator() {
  return (
    <svg
      width="10"
      height="8"
      viewBox="0 0 10 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <motion.path
        d="M8.75 1L3.5 7L1.25 4.75"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        variants={variants}
        initial="initial"
        animate="animate"
        exit="exit"
      />
    </svg>
  )
}

const variants = {
  initial: {
    pathLength: 0,
    pathOffset: 1,
  },
  animate: {
    pathLength: 1,
    pathOffset: 0,
  },
  exit: {
    pathLength: 0,
  },
}
