'use client'
export function Exit(props) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M9 18C13.9706 18 18 13.9706 18 9C18 4.02944 13.9706 0 9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18Z"
        fill="#DFDFE0"
      />
      <path
        d="M9 18C13.9706 18 18 13.9706 18 9C18 4.02944 13.9706 0 9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18Z"
        fill="#DFDFE0"
      />
      <path
        d="M15.3647 12.4468H13.1391C13.1391 10.7579 12.638 9.47999 11.6115 8.5586C9.97517 7.09113 7.3949 6.86969 5.98185 6.86969V12.4468H3.76562V4.8111L4.7527 4.70038C4.96663 4.67599 10.0071 4.13741 13.0884 6.89409C14.599 8.24334 15.3647 10.1124 15.3647 12.4468Z"
        fill="#6B6E73"
      />
    </svg>
  )
}
