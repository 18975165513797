'use client'
import { styled } from "../config";

function Svg(props) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9 10.125C9.31066 10.125 9.5625 9.87316 9.5625 9.5625C9.5625 9.25184 9.31066 9 9 9C8.68934 9 8.4375 9.25184 8.4375 9.5625C8.4375 9.87316 8.68934 10.125 9 10.125Z"
        fill="#BEC1C4"
      />
      <path
        d="M9.84375 2.86523V2.53125C9.84375 2.30747 9.75486 2.09286 9.59662 1.93463C9.43839 1.77639 9.22378 1.6875 9 1.6875C8.77623 1.6875 8.56161 1.77639 8.40338 1.93463C8.24515 2.09286 8.15625 2.30747 8.15625 2.53125V2.86523C7.09075 2.99899 6.07287 3.38635 5.18801 3.9948L4.81641 3.62109C4.6579 3.46259 4.44292 3.37354 4.21875 3.37354C3.99459 3.37354 3.7796 3.46259 3.6211 3.62109C3.46259 3.7796 3.37354 3.99459 3.37354 4.21875C3.37354 4.44291 3.46259 4.6579 3.6211 4.81641L3.92344 5.11875C2.84351 6.34704 2.24854 7.92698 2.25 9.5625C2.25 13.2845 5.27801 16.3125 9 16.3125C12.722 16.3125 15.75 13.2845 15.75 9.5625C15.75 6.12633 13.1688 3.28148 9.84375 2.86523ZM9 11.25C8.60201 11.2499 8.21687 11.1091 7.91262 10.8525C7.60837 10.596 7.40459 10.2401 7.33729 9.84787C7.26998 9.45562 7.34348 9.0522 7.54481 8.70888C7.74613 8.36557 8.06231 8.10446 8.4375 7.97168V5.34375C8.4375 5.19457 8.49677 5.05149 8.60225 4.946C8.70774 4.84051 8.85082 4.78125 9 4.78125C9.14919 4.78125 9.29226 4.84051 9.39775 4.946C9.50324 5.05149 9.5625 5.19457 9.5625 5.34375V7.97168C9.93769 8.10446 10.2539 8.36557 10.4552 8.70888C10.6565 9.0522 10.73 9.45562 10.6627 9.84787C10.5954 10.2401 10.3916 10.596 10.0874 10.8525C9.78313 11.1091 9.39799 11.2499 9 11.25Z"
        fill="#BEC1C4"
      />
    </svg>
  );
}
export const Watch = styled(Svg);
