'use client'
import { styled } from '../config'

function Svg(props: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      {...props}>
      <path
        d="M17.5 10.5C17.5 6.35938 14.1406 3 10 3C5.85938 3 2.5 6.35938 2.5 10.5C2.5 14.6406 5.85938 18 10 18C14.1406 18 17.5 14.6406 17.5 10.5Z"
        stroke="black"
        strokeWidth="1.25"
        strokeMiterlimit="10"
      />
      <path
        d="M13.75 7.375L8.5 13.625L6.25 11.125"
        stroke="black"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export const CheckRounded = styled(Svg, {})
