'use client'
import { SVGProps } from 'react'

export function CheckFill(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="currentColor"
      {...props}
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.0013 1.66675C5.39893 1.66675 1.66797 5.39771 1.66797 10.0001C1.66797 14.6025 5.39893 18.3334 10.0013 18.3334C14.6037 18.3334 18.3346 14.6025 18.3346 10.0001C18.3346 5.39771 14.6037 1.66675 10.0013 1.66675ZM13.1463 8.44447C13.4377 8.08827 13.3852 7.56325 13.029 7.27181C12.6728 6.98037 12.1478 7.03287 11.8563 7.38907L8.68944 11.2597L7.67389 10.2442C7.34845 9.91875 6.82082 9.91875 6.49538 10.2442C6.16994 10.5696 6.16994 11.0973 6.49538 11.4227L8.16205 13.0894C8.32853 13.2558 8.55766 13.3441 8.79281 13.3324C9.02796 13.3207 9.24717 13.21 9.39627 13.0278L13.1463 8.44447Z"
        fill="#parent"
      />
    </svg>
  )
}
