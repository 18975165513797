'use client'
import React, { forwardRef } from 'react'

export const ArrowDecreaseStraight = forwardRef<
  SVGSVGElement,
  React.SVGProps<SVGSVGElement>
>(function ArrowDecreaseStraightImpl(props, forwardRef) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      {...props}
      ref={forwardRef}>
      <path
        d="M12.5001 15.2015C12.6509 15.0508 12.7433 14.8412 12.743 14.6111L12.743 14.5099C12.743 14.0491 12.3699 13.676 11.9097 13.6766L7.3568 13.6766L15.0586 5.97485C15.3838 5.64959 15.3838 5.12161 15.0585 4.79634C14.7333 4.47107 14.2053 4.47107 13.88 4.79634L6.17828 12.4981V7.94518C6.17828 7.48438 5.80525 7.11134 5.34504 7.11193H5.24376C4.78296 7.11193 4.40993 7.48497 4.41052 7.94518V14.6111C4.41052 15.0719 4.78355 15.445 5.24376 15.4444L11.9097 15.4444C12.1401 15.4444 12.3493 15.3523 12.5001 15.2015Z"
        fill="#parent"
      />
    </svg>
  )
})
