'use client'
export function BellFilled(props) {
  return (
    <svg
      width="13"
      height="14"
      viewBox="0 0 13 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M11.7576 9.66594C11.7058 9.60344 11.6548 9.54094 11.6048 9.48063C10.9173 8.64906 10.5014 8.14719 10.5014 5.79313C10.5014 4.57438 10.2098 3.57437 9.63514 2.82437C9.21139 2.27031 8.63858 1.85 7.88358 1.53938C7.87386 1.53397 7.86518 1.52688 7.85795 1.51844C7.58639 0.609062 6.84326 0 6.00514 0C5.16701 0 4.4242 0.609062 4.15264 1.5175C4.14539 1.52563 4.13684 1.5325 4.12733 1.53781C2.36545 2.26312 1.5092 3.65469 1.5092 5.79219C1.5092 8.14719 1.09389 8.64906 0.405764 9.47969C0.355764 9.54 0.304826 9.60125 0.252951 9.665C0.118951 9.8266 0.0340515 10.0232 0.0082991 10.2316C-0.0174533 10.4399 0.0170198 10.6513 0.107639 10.8406C0.300451 11.2469 0.711389 11.4991 1.18045 11.4991H10.8333C11.3001 11.4991 11.7083 11.2472 11.9017 10.8428C11.9927 10.6534 12.0275 10.4419 12.002 10.2333C11.9764 10.0247 11.8916 9.8278 11.7576 9.66594ZM6.00514 14C6.45671 13.9996 6.89977 13.8771 7.28731 13.6453C7.67486 13.4135 7.99245 13.0811 8.20639 12.6834C8.21647 12.6644 8.22145 12.643 8.22084 12.6215C8.22023 12.5999 8.21406 12.5789 8.20293 12.5604C8.1918 12.542 8.17608 12.5267 8.1573 12.5161C8.13852 12.5055 8.11732 12.5 8.09576 12.5H3.91514C3.89355 12.4999 3.87232 12.5054 3.85349 12.516C3.83467 12.5266 3.81891 12.5418 3.80774 12.5603C3.79657 12.5788 3.79037 12.5998 3.78975 12.6214C3.78913 12.643 3.79411 12.6644 3.8042 12.6834C4.01812 13.0811 4.33565 13.4134 4.72314 13.6452C5.11063 13.877 5.55362 13.9996 6.00514 14Z"
        fill="#parent"
      />
    </svg>
  )
}
