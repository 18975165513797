'use client'
import { styled } from "../config";

function Svg(props) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.875 3.50007C15.875 2.90333 15.6379 2.33104 15.216 1.90908C14.794 1.48712 14.2217 1.25007 13.625 1.25007H13.0625V0.703391C13.0625 0.400696 12.8298 0.140891 12.5271 0.125774C12.451 0.122109 12.375 0.133925 12.3037 0.160503C12.2323 0.187082 12.1671 0.227871 12.112 0.280399C12.0568 0.332926 12.0129 0.396098 11.983 0.466088C11.953 0.536078 11.9375 0.611427 11.9375 0.687571V1.25007H4.0625V0.703391C4.0625 0.400696 3.82977 0.140891 3.52707 0.125774C3.45102 0.122109 3.37501 0.133925 3.30365 0.160503C3.2323 0.187082 3.16708 0.227871 3.11196 0.280399C3.05684 0.332926 3.01295 0.396098 2.98296 0.466088C2.95297 0.536078 2.93751 0.611427 2.9375 0.687571V1.25007H2.375C1.77826 1.25007 1.20597 1.48712 0.78401 1.90908C0.362053 2.33104 0.125 2.90333 0.125 3.50007V3.92195C0.125 3.95924 0.139816 3.99501 0.166188 4.02138C0.19256 4.04776 0.228329 4.06257 0.265625 4.06257H15.7344C15.7717 4.06257 15.8074 4.04776 15.8338 4.02138C15.8602 3.99501 15.875 3.95924 15.875 3.92195V3.50007ZM0.125 13.6251C0.125 14.2218 0.362053 14.7941 0.78401 15.2161C1.20597 15.638 1.77826 15.8751 2.375 15.8751H13.625C14.2217 15.8751 14.794 15.638 15.216 15.2161C15.6379 14.7941 15.875 14.2218 15.875 13.6251V5.3282C15.875 5.2909 15.8602 5.25513 15.8338 5.22876C15.8074 5.20239 15.7717 5.18757 15.7344 5.18757H0.265625C0.228329 5.18757 0.19256 5.20239 0.166188 5.22876C0.139816 5.25513 0.125 5.2909 0.125 5.3282V13.6251Z"
        fill={props.fill}
      />
    </svg>
  );
}

export const Calendar = styled(Svg, {
  fill: "#C0C0C0",
});
