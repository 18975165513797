'use client'
import React from 'react'
import { forwardRef } from 'react'

export const Book = forwardRef<SVGSVGElement, React.SVGProps<SVGSVGElement>>(
  function BookImpl(props, forwardRef) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        {...props}
        ref={forwardRef}>
        <path
          d="M8.20748 4.84499C7.00325 4.28445 5.32006 4.00987 3.06658 4.00012C2.85405 3.9973 2.6457 4.05772 2.4693 4.17332C2.3245 4.26873 2.20598 4.39737 2.12415 4.54792C2.04232 4.69847 1.99968 4.86632 2 5.03671V14.3335C2 14.9619 2.45863 15.436 3.06658 15.436C5.43538 15.436 7.81152 15.6518 9.23473 16.9633C9.2542 16.9813 9.27867 16.9934 9.30511 16.9979C9.33154 17.0025 9.35877 16.9994 9.38341 16.989C9.40806 16.9787 9.42903 16.9614 9.44372 16.9395C9.45841 16.9176 9.46617 16.892 9.46604 16.8658V5.91147C9.4661 5.83756 9.44989 5.7645 9.41849 5.69723C9.38709 5.62996 9.34125 5.57004 9.28406 5.52153C8.95806 5.24982 8.59593 5.02225 8.20748 4.84499ZM17.5294 4.17234C17.3529 4.05703 17.1445 3.99695 16.9321 4.00012C14.6786 4.00987 12.9954 4.28315 11.7912 4.84499C11.4028 5.02193 11.0405 5.24905 10.7143 5.52023C10.6572 5.56883 10.6115 5.62877 10.5801 5.69602C10.5488 5.76328 10.5326 5.8363 10.5326 5.91017V16.8652C10.5326 16.8903 10.5402 16.9149 10.5545 16.9359C10.5687 16.9568 10.589 16.9732 10.6128 16.983C10.6366 16.9927 10.6628 16.9954 10.6881 16.9907C10.7135 16.986 10.7368 16.9741 10.7553 16.9565C11.6109 16.1279 13.1124 15.4351 16.9334 15.4354C17.2163 15.4354 17.4876 15.3258 17.6876 15.1308C17.8876 14.9358 18 14.6713 18 14.3956V5.03703C18.0004 4.86631 17.9577 4.69812 17.8756 4.54732C17.7935 4.39651 17.6746 4.26773 17.5294 4.17234Z"
          fill="parent"
        />
      </svg>
    )
  }
)
