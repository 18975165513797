'use client'
import { SVGProps } from 'react'

export function Info(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      preserveAspectRatio={'xMidYMid meet'}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <g transform="translate(8.2,5.3)">
        <path
          d="M2 9V4.04C2 4.01791 1.98209 4 1.96 4H1"
          stroke={props.color || '#1B2026'}
          strokeWidth="2"
          strokeLinecap="round"
        />

        <circle cx="2" cy="1" r="1" fill={props.color || '#1B2026'} />
      </g>

      <circle
        cx="10"
        cy="10"
        r="9.25"
        stroke={props.color || '#1B2026'}
        strokeWidth="1.5"
      />
    </svg>
  )
}
