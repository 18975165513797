import { forwardRef, SVGProps } from 'react'

export const TransferBackground = forwardRef<
  SVGSVGElement,
  SVGProps<SVGSVGElement>
>(function TrashBackgroundImpl(props, forwardRef: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="31"
      height="31"
      viewBox="0 0 31 31"
      fill="none"
      {...props}>
      <rect width="31" height="31" rx="4" fill="#F1F6FE" />
      <path
        d="M21.705 12.3919L21.696 12.401V12.4039L18.9193 15.207C18.5688 15.5609 17.9997 15.5609 17.6492 15.207C17.2986 14.853 17.2986 14.278 17.6492 13.924L18.8505 12.7112L18.9033 12.658H18.8283H9.92857C9.43249 12.658 9.03125 12.2534 9.03125 11.7518C9.03125 11.2503 9.43249 10.8457 9.92857 10.8457H18.8283H18.9033L18.8505 10.7924L17.6492 9.57966C17.2986 9.22566 17.2986 8.65062 17.6492 8.29662C17.9997 7.94279 18.5688 7.94279 18.9193 8.29662L21.705 11.1088C22.0557 11.4628 22.0557 12.0379 21.705 12.3919ZM13.3479 22.7034C13.0119 23.0425 12.4751 23.0566 12.1225 22.7456L12.0807 22.7034L9.29497 19.8912C8.94431 19.5372 8.94431 18.9621 9.29497 18.6081L12.0807 15.7959C12.4312 15.4421 13.0003 15.4421 13.3508 15.7959C13.7014 16.1499 13.7014 16.7249 13.3508 17.0789L12.1495 18.2917L12.0967 18.3449H12.1717H21.0714C21.5675 18.3449 21.9688 18.7496 21.9688 19.2511C21.9688 19.7526 21.5675 20.1573 21.0714 20.1573H12.1717H12.0967L12.1495 20.2105L13.3479 21.4203C13.6985 21.7743 13.6985 22.3494 13.3479 22.7034Z"
        fill="black"
        stroke="#D2DCED"
        strokeWidth="0.0625"
      />
    </svg>
  )
})
