'use client'
import { styled } from '../config'

function TGIcon(props) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <g clipPath="url(#clip0_1490_85)">
        <path
          d="M8.00016 1.3335L1.3335 4.66683L8.00016 8.00016L14.6668 4.66683L8.00016 1.3335Z"
          stroke="black"
          strokeWidth="1.33333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1.3335 11.3335L8.00016 14.6668L14.6668 11.3335"
          stroke="black"
          strokeWidth="1.33333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1.3335 8L8.00016 11.3333L14.6668 8"
          stroke="black"
          strokeWidth="1.33333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1490_85">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export const TitleGroupIcon = styled(TGIcon, {})
