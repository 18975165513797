import {SVGProps} from 'react'

export function PenBackground(props: SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none" {...props}>
      <rect width="26" height="26" rx="4" fill="black" fillOpacity="0.1"/>
      <path d="M14.5359 8.66297L15.8343 7.36463C16.3204 6.87846 17.1087 6.87846 17.5949 7.36463L18.6354 8.40514C19.1215 8.89131 19.1215 9.67956 18.6354 10.1657L17.337 11.4641M14.5359 8.66297L8.18232 15.0166C8.06558 15.1333 8 15.2916 8 15.4567V18H10.5433C10.7084 18 10.8667 17.9344 10.9834 17.8177L17.337 11.4641M14.5359 8.66297L17.337 11.4641" stroke="#5C5C5C" stroke-width="1.41026" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  )
}
