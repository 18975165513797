'use client'
import { styled } from '../config'

function Svg(props: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="107"
      height="97"
      viewBox="0 0 107 97"
      fill="none"
      {...props}>
      <path
        d="M1.80616 79.6658C2.29556 79.3857 7.8192 77.0472 35.2984 89.8579C35.2183 89.9669 35.1843 90.0184 35.1843 90.0184C35.1843 90.0184 6.61701 99.639 1.01981 87.7871C-0.651621 84.2587 -0.172682 81.8291 1.75843 79.6932L1.80616 79.6658Z"
        fill="#5368E5"
      />
      <path
        d="M94.1653 84.5115C88.1952 89.5305 78.8327 93.1734 69.7988 88.3292C71.2264 87.417 72.7009 86.4774 74.2047 85.5055C83.2771 79.6763 91.2683 83.5766 94.1653 84.5115Z"
        fill="#5368E5"
      />
      <path
        d="M104.86 22.7843C110.959 73.5751 96.464 82.7931 94.8503 84.1933C94.3832 84.5987 94.1938 84.5691 93.4874 84.3873L93.4754 84.3941C90.4858 83.6016 83.2781 79.6771 74.2055 85.5056C72.7016 86.4776 71.2267 87.4172 69.7996 88.3294C60.5445 94.1991 53.0706 98.3356 47.6861 95.9348C43.119 93.6049 39.001 91.5885 35.2988 89.8566C7.81968 77.0459 2.29604 79.3843 1.80664 79.6646C2.3745 79.3553 7.58376 76.4677 14.5979 71.3899C14.6218 71.3762 14.6457 71.3625 14.6508 71.3438C24.0262 64.5493 36.5818 53.842 45.6067 40.0685C51.7505 30.7024 57.761 6.95843 69.3578 2.2996C93.1914 -6.59153 103.87 12.4532 104.86 22.7843Z"
        fill="url(#paint0_radial_6661_12140)"
      />
      <path
        d="M78.5068 21.8904C79.2467 19.9368 79.1746 18.0987 78.3458 17.7849C77.517 17.471 76.2454 18.8002 75.5056 20.7538C74.7658 22.7073 74.8379 24.5455 75.6666 24.8593C76.4954 25.1732 77.767 23.8439 78.5068 21.8904Z"
        fill="#5368E5"
      />
      <path
        d="M93.6809 24.3864C94.2053 22.3643 93.9349 20.5448 93.077 20.3223C92.2192 20.0999 91.0987 21.5588 90.5744 23.5809C90.05 25.603 90.3204 27.4225 91.1783 27.6449C92.0361 27.8674 93.1566 26.4085 93.6809 24.3864Z"
        fill="#5368E5"
      />
      <path
        d="M84.4449 31.8104C84.3206 31.8103 84.2 31.7677 84.1033 31.6896C84.0065 31.6116 83.9394 31.5028 83.913 31.3813C83.8159 30.9415 83.3837 29.6401 82.6985 29.4467C82.0132 29.2533 80.6691 30.0042 80.1253 30.3981C80.0082 30.4829 79.8623 30.5177 79.7196 30.4949C79.5769 30.472 79.4491 30.3934 79.3643 30.2764C79.2796 30.1593 79.2448 30.0134 79.2676 29.8707C79.2904 29.728 79.369 29.6002 79.486 29.5154C79.7004 29.3603 81.6199 28.01 82.9943 28.3978C84.4279 28.8023 84.9258 30.9104 84.9777 31.1495C84.995 31.2291 84.9943 31.3116 84.9755 31.3908C84.9568 31.4701 84.9206 31.5442 84.8694 31.6076C84.8183 31.671 84.7536 31.7221 84.6801 31.7572C84.6066 31.7923 84.5261 31.8105 84.4447 31.8104H84.4449Z"
        fill="#5368E5"
      />
      <defs>
        <radialGradient
          id="paint0_radial_6661_12140"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(58.9205 58.9888) rotate(-133.144) scale(124.735 158.879)">
          <stop stopColor="#F0F6FF" />
          <stop offset="1" stopColor="#D5E4F5" />
        </radialGradient>
      </defs>
    </svg>
  )
}

export const Ghost = styled(Svg, {})
