'use client'
import clsx from 'clsx'
import { motion } from 'framer-motion'

export function Loading({ className }: { className?: string }) {
  return (
    <motion.div className={clsx('loading block', className)}>
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 15 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M12.8535 3.38867C13.5873 4.34416 14.0488 5.47433 14.1975 6.65976C14.1991 6.67213 14.1974 6.67823 14.1962 6.68186C14.1945 6.68664 14.1903 6.69517 14.1803 6.7056C14.159 6.72777 14.1187 6.75 14.064 6.75C14.0183 6.75 13.9663 6.7332 13.912 6.68159C13.8544 6.62685 13.8065 6.54228 13.79 6.44412C13.618 5.41986 13.1975 4.44746 12.5584 3.61526C11.7027 2.50102 10.503 1.7005 9.14571 1.33799C7.78837 0.975487 6.34933 1.07129 5.05202 1.61052C3.75472 2.14975 2.67176 3.10223 1.9713 4.32006C1.27084 5.5379 0.992078 6.95294 1.17831 8.34545C1.36454 9.73796 2.00533 11.03 3.00119 12.021C3.99705 13.012 5.29224 13.6464 6.68565 13.8258C7.72636 13.9598 8.77834 13.8343 9.75015 13.4679C9.84328 13.4328 9.94047 13.4317 10.0168 13.4539C10.0887 13.4749 10.1294 13.5114 10.1523 13.5509C10.1798 13.5982 10.1809 13.6442 10.1724 13.6738C10.1685 13.6877 10.1632 13.6956 10.1599 13.6994C10.1574 13.7023 10.1529 13.7068 10.1415 13.7117C9.04201 14.1792 7.83304 14.3486 6.63815 14.1948C5.16347 14.0049 3.79273 13.3335 2.73878 12.2847C1.68484 11.2359 1.00667 9.86849 0.809576 8.39476C0.612484 6.92103 0.907502 5.42345 1.64882 4.13458C2.39013 2.84572 3.53626 1.83768 4.90924 1.26699C6.28221 0.696313 7.80519 0.594925 9.2417 0.978574C10.6782 1.36222 11.9478 2.20944 12.8535 3.38867Z"
          stroke="currentColor"
          strokeWidth="1.5"
        />
      </svg>
    </motion.div>
  )
}
