'use client'
import { styled } from '../config'

function Svg(props) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M0.666626 2.66699V6.66699H4.66663"
        stroke="#737980"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.33996 10.0001C2.77222 11.227 3.59152 12.2802 4.6744 13.001C5.75728 13.7218 7.04508 14.0712 8.34377 13.9964C9.64246 13.9217 10.8817 13.4269 11.8747 12.5866C12.8677 11.7464 13.5608 10.6061 13.8494 9.3377C14.1381 8.06929 14.0067 6.74143 13.475 5.55419C12.9434 4.36696 12.0403 3.38466 10.9018 2.7553C9.76339 2.12595 8.45123 1.88364 7.16308 2.06487C5.87493 2.24611 4.68057 2.84108 3.75996 3.76013L0.666626 6.6668"
        stroke="#737980"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export const Reset = styled(Svg, {
  stroke: '#1B2026',
})
