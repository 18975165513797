'use client'
import { styled } from '../config'

function Svg(props) {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="#B0ACC1" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20ZM10 5.625C10.3452 5.625 10.625 5.90482 10.625 6.25V9.375H13.75C14.0952 9.375 14.375 9.65482 14.375 10C14.375 10.3452 14.0952 10.625 13.75 10.625H10.625V13.75C10.625 14.0952 10.3452 14.375 10 14.375C9.65482 14.375 9.375 14.0952 9.375 13.75V10.625H6.25C5.90482 10.625 5.625 10.3452 5.625 10C5.625 9.65482 5.90482 9.375 6.25 9.375H9.375V6.25C9.375 5.90482 9.65482 5.625 10 5.625Z"
        fill="parent"
      />
    </svg>
  )
}

export const PlusButton = styled(Svg)
