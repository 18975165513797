'use client'
import { styled } from '../config'

function Svg(props) {
  return (
    <svg
      width="8"
      height="12"
      viewBox="0 0 8 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M7.456 4.43464C7.1461 3.55368 6.69442 2.73775 6.1215 2.02393C5.13 0.794196 3.6 0 2.4 0C2.875 1.875 2.041 3.13714 1.28025 4.3867C0.65 5.42036 0 6.48938 0 7.71429C0 10.0773 1.7945 12 4 12C6.2055 12 8 10.0773 8 7.71429C8 6.55446 7.817 5.45116 7.456 4.43464ZM4.80825 10.3578C4.55 10.6524 4.22625 10.7143 4 10.7143C3.77375 10.7143 3.45 10.6524 3.19175 10.3578C2.9335 10.0631 2.8 9.59571 2.8 9C2.8 8.32661 3.0205 7.81393 3.2335 7.31839C3.3955 6.94152 3.6 6.58929 3.61125 6.1267C3.89375 6.2842 4.203 6.57964 4.47475 6.96455C4.929 7.60741 5.19975 8.36866 5.19975 9.00027C5.2 9.59571 5.0645 10.0653 4.80825 10.3578Z"
        fill="#EE4562"
      />
    </svg>

    // <svg
    //   width="10"
    //   height="14"
    //   viewBox="0 0 10 14"
    //   fill="#737980"
    //   xmlns="http://www.w3.org/2000/svg"
    //   {...props}>
    //   <path
    //     d="M8.0625 5.25H7.625V3.0625C7.625 2.36631 7.34844 1.69863 6.85616 1.20634C6.36387 0.714062 5.69619 0.4375 5 0.4375C4.30381 0.4375 3.63613 0.714062 3.14384 1.20634C2.65156 1.69863 2.375 2.36631 2.375 3.0625V5.25H1.9375C1.47353 5.25051 1.0287 5.43504 0.700623 5.76312C0.372544 6.0912 0.188007 6.53603 0.1875 7V11.8125C0.188007 12.2765 0.372544 12.7213 0.700623 13.0494C1.0287 13.3775 1.47353 13.562 1.9375 13.5625H8.0625C8.52647 13.562 8.9713 13.3775 9.29938 13.0494C9.62746 12.7213 9.81199 12.2765 9.8125 11.8125V7C9.81199 6.53603 9.62746 6.0912 9.29938 5.76312C8.9713 5.43504 8.52647 5.25051 8.0625 5.25ZM6.75 5.25H3.25V3.0625C3.25 2.59837 3.43437 2.15325 3.76256 1.82506C4.09075 1.49687 4.53587 1.3125 5 1.3125C5.46413 1.3125 5.90925 1.49687 6.23744 1.82506C6.56563 2.15325 6.75 2.59837 6.75 3.0625V5.25Z"
    //     fill="#parent"
    //   />
    // </svg>
  )
}

export const Fire = styled(Svg)
