import React from 'react'
import { Trash } from './trash'
import { ButtonMotion } from '../components/button-motion'
import { twMerge } from 'tailwind-merge'

export function TrashBackground({
  onClickButton,
  className,
}: {
  onClickButton?: () => void
  className?: string
}) {
  return (
    <ButtonMotion onClickButton={onClickButton}>
      <Trash className={twMerge('h-[16px] w-[16px]', className)} />
    </ButtonMotion>
  )
}
