'use client'
import { styled } from '../config'

function Svg(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      {...props}>
      <circle cx="8" cy="8" r="7.5" fill="white" stroke="#A7A7A7" />
      <path
        d="M8 4V12M12 8H4"
        stroke="#A7A7A7"
        strokeWidth="0.666667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export const AddCircle = styled(Svg)
