'use client'
import React from 'react'
import { forwardRef } from 'react'

export const CollapsibleOpen = forwardRef<
  SVGSVGElement,
  React.SVGProps<SVGSVGElement>
>(function CollapsibleOpenImpl(props, forwardRef) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="9"
      height="6"
      viewBox="0 0 9 6"
      fill="none"
      stroke="#737980"
      ref={forwardRef}
      {...props}>
      <path
        d="M7.5 1.25L4.25 4.75L1 1.25"
        stroke="#parent"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
    // <svg
    //   xmlns="http://www.w3.org/2000/svg"
    //   width="20"
    //   height="21"
    //   viewBox="0 0 20 21"
    //   fill="none"
    //   ref={forwardRef}
    //   {...props}>
    //   <path
    //     d="M7.49988 5.29846C7.3491 5.44924 7.25675 5.65876 7.25704 5.88887L7.25704 5.99015C7.25704 6.45094 7.63008 6.82398 8.09029 6.82339L12.6432 6.82339L4.94145 14.5251C4.61618 14.8504 4.61618 15.3784 4.94145 15.7037C5.26672 16.0289 5.79469 16.0289 6.11996 15.7037L13.8217 8.0019L13.8217 12.5548C13.8217 13.0156 14.1948 13.3887 14.655 13.3881L14.7562 13.3881C15.217 13.3881 15.5901 13.015 15.5895 12.5548L15.5895 5.88887C15.5895 5.42807 15.2164 5.05504 14.7562 5.05562L8.09029 5.05562C7.85989 5.05562 7.65065 5.14769 7.49988 5.29846Z"
    //     fill="#parent"
    //   />
    // </svg>
  )
})
