'use client'
import { styled } from '../config'

function Svg(props) {
  return (
    <svg
      width="161"
      height="161"
      viewBox="0 0 161 161"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M48.6207 42.4847C48.6207 42.4847 65.8844 55.5855 92.1297 31.0893C115.438 9.33493 134.47 43.6585 134.603 60.0955C134.775 81.3877 111.294 98.4248 122.69 112.41C134.085 126.395 100.091 149.489 81.7705 129.503C58.9799 104.64 52.8058 124.841 39.8153 124.841C30.4919 124.841 11.35 101.675 24.2763 84.4398C35.1536 69.9368 29.2214 65.1235 26.3486 60.0955C22.2045 52.844 32.0459 33.1613 48.6207 42.4847Z"
        fill="#DEE9FF"
      />
      <path
        d="M117.928 71.4351C118.856 71.7799 119.87 71.8029 120.835 71.5814C124.877 70.6536 138.777 68.1969 136.361 78.9676C135.835 81.1046 127.695 93.0721 139.288 97.4407C140.137 97.7649 141.036 97.9418 141.945 97.964C145.514 98.0375 152.587 97.1948 155.548 94.1813C159.124 90.541 155.217 92.2673 155.217 92.2673C155.217 92.2673 141.622 98.2002 136.819 91.9058C136.285 91.2055 135.945 90.1883 135.847 89.3126C135.678 87.7943 136.352 85.788 137.238 84.0415C138.436 81.6777 144.191 71.0444 131.697 67.9467C128.819 67.2614 124.639 66.6457 119.249 68.915C116.105 70.2386 116.802 71.0166 117.928 71.4351Z"
        fill="#BDD6F9"
      />
      <path
        d="M120.71 67.4657L117.092 68.82C115.843 69.2875 115.209 70.6791 115.677 71.9283L115.856 72.4075C116.323 73.6567 117.715 74.2903 118.964 73.8228L122.583 72.4685C123.832 72.001 124.466 70.6094 123.998 69.3602L123.819 68.881C123.351 67.6318 121.96 66.9982 120.71 67.4657Z"
        fill="#5368E5"
      />
      <path
        d="M98.4985 71.3842L86.5645 75.8507C85.9399 76.0844 85.6231 76.7802 85.8568 77.4048L85.9039 77.5304C86.1376 78.155 86.8334 78.4718 87.458 78.2381L99.392 73.7716C100.017 73.5378 100.333 72.842 100.1 72.2175L100.053 72.0919C99.8189 71.4673 99.1231 71.1505 98.4985 71.3842Z"
        fill="white"
      />
      <path
        d="M101.829 80.2836L89.8946 84.7501C89.27 84.9838 88.9532 85.6797 89.1869 86.3042L89.2339 86.4298C89.4677 87.0544 90.1635 87.3712 90.7881 87.1375L102.722 82.671C103.347 82.4372 103.664 81.7414 103.43 81.1168L103.383 80.9912C103.149 80.3667 102.453 80.0498 101.829 80.2836Z"
        fill="white"
      />
      <path
        d="M112.848 65.3114L103.176 68.9313C101.927 69.3988 101.293 70.7905 101.761 72.0396L105.063 80.8631C105.53 82.1122 106.922 82.7458 108.171 82.2783L117.843 78.6584C119.093 78.1908 119.726 76.7992 119.259 75.5501L115.956 66.7266C115.489 65.4775 114.097 64.8438 112.848 65.3114Z"
        fill="#5895F9"
      />
      <path
        d="M104.174 67.6258L94.502 71.2458C93.2529 71.7133 92.6192 73.1049 93.0867 74.3541L97.0265 84.8808C97.494 86.1299 98.8856 86.7636 100.135 86.2961L109.807 82.6761C111.056 82.2086 111.69 80.817 111.222 79.5678L107.283 69.0411C106.815 67.7919 105.423 67.1583 104.174 67.6258Z"
        fill="#699CFF"
      />
      <path
        d="M105.689 65.4898L105.613 65.5182C104.781 65.8299 104.358 66.7576 104.67 67.5904L110.189 82.3372C110.501 83.1699 111.428 83.5924 112.261 83.2807L112.337 83.2523C113.17 82.9406 113.592 82.0129 113.281 81.1801L107.761 66.4333C107.45 65.6006 106.522 65.1781 105.689 65.4898Z"
        fill="#5368E5"
      />
      <path
        d="M7.06286 74.9183C7.06286 74.9183 26.4881 69.4593 23.6679 82.0364C23.1782 84.0264 16.128 94.2732 24.5258 99.1929C26.329 100.249 28.4099 100.717 30.4996 100.706C34.2463 100.687 40.186 99.9657 42.8544 97.2497C46.4305 93.6094 42.5237 95.3357 42.5237 95.3357C42.5237 95.3357 31.3674 99.9248 25.6279 96.2667C23.6682 95.0176 22.7798 92.5993 23.312 90.337C23.584 89.2132 23.9982 88.1288 24.5446 87.1099C25.7929 84.772 31.4976 74.1127 19.004 71.0151C16.1259 70.3297 11.9458 69.7141 6.55595 71.9834C1.16607 74.2527 7.06286 74.9183 7.06286 74.9183Z"
        fill="#BDD6F9"
      />
      <path
        d="M45.4663 92.5213L41.8477 93.8757C40.5986 94.3432 39.9649 95.7348 40.4324 96.984L40.6118 97.4632C41.0793 98.7124 42.4709 99.346 43.7201 98.8785L47.3387 97.5242C48.5879 97.0567 49.2215 95.6651 48.754 94.4159L48.5746 93.9366C48.1071 92.6875 46.7155 92.0538 45.4663 92.5213Z"
        fill="#5368E5"
      />
      <path
        d="M51.5798 86.5672L46.2169 88.5744C44.9677 89.0419 44.3341 90.4335 44.8016 91.6827L47.3876 98.5923C47.8551 99.8415 49.2467 100.475 50.4959 100.008L55.8588 98.0004C57.108 97.5329 57.7416 96.1413 57.2741 94.8921L54.6881 87.9825C54.2206 86.7334 52.829 86.0997 51.5798 86.5672Z"
        fill="#5895F9"
      />
      <path
        d="M60.5076 81.2476L52.5098 84.2409C51.2607 84.7084 50.627 86.1 51.0945 87.3492L55.0343 97.8759C55.5018 99.1251 56.8934 99.7587 58.1426 99.2912L66.1403 96.2979C67.3895 95.8304 68.0231 94.4388 67.5556 93.1897L63.6158 82.6629C63.1483 81.4138 61.7567 80.7801 60.5076 81.2476Z"
        fill="#699CFF"
      />
      <path
        d="M62.2028 79.0674L62.127 79.0958C61.2942 79.4075 60.8718 80.3352 61.1835 81.168L66.7027 95.9148C67.0143 96.7476 67.9421 97.17 68.7748 96.8583L68.8507 96.8299C69.6835 96.5183 70.1059 95.5905 69.7942 94.7577L64.275 80.011C63.9634 79.1782 63.0356 78.7558 62.2028 79.0674Z"
        fill="#5368E5"
      />
      <path
        d="M82.6264 69.6728C82.3384 69.6408 82.074 69.4983 81.8889 69.2754C81.7038 69.0524 81.6124 68.7663 81.634 68.4773L82.2307 57.5776C82.2663 56.9265 82.8161 56.4319 83.4401 56.4894L84.3095 56.5699C84.9334 56.6275 85.3801 57.2139 85.2929 57.8604L83.8265 68.6796C83.7927 68.9678 83.6492 69.2319 83.4256 69.4169C83.2021 69.6019 82.9158 69.6935 82.6264 69.6728Z"
        fill="#5368E5"
      />
      <path
        d="M77.474 70.6124C77.3844 70.6388 77.2906 70.6472 77.1977 70.6372C77.1049 70.6273 77.0149 70.5991 76.933 70.5544C76.851 70.5096 76.7787 70.4492 76.7201 70.3765C76.6615 70.3038 76.6178 70.2202 76.5915 70.1307L74.4605 63.3505C74.3985 63.1576 74.4156 62.9481 74.5079 62.7678C74.6002 62.5876 74.7603 62.4513 74.953 62.3889L75.5048 62.2224C75.5994 62.1948 75.6988 62.1871 75.7966 62.1998C75.8944 62.2125 75.9885 62.2454 76.0729 62.2963C76.1574 62.3472 76.2304 62.415 76.2873 62.4956C76.3442 62.5761 76.3838 62.6676 76.4035 62.7642L77.983 69.7109C78.027 69.8977 77.999 70.0941 77.9047 70.2612C77.8104 70.4282 77.6566 70.5537 77.474 70.6124Z"
        fill="#5368E5"
      />
      <path
        d="M74.5976 74.5225C74.2158 74.9956 73.4479 74.99 72.8568 74.5097L62.362 65.983C61.7351 65.4737 61.5501 64.6306 61.9552 64.1291L62.5198 63.4297C62.9248 62.928 63.7568 62.9691 64.35 63.5202L74.2803 72.7464C74.8395 73.2659 74.9794 74.0494 74.5976 74.5225Z"
        fill="#5368E5"
      />
      <path
        d="M79.448 96.5464C79.7183 96.6508 79.9376 96.8561 80.0596 97.1189C80.1816 97.3818 80.1969 97.6817 80.1022 97.9556L76.7416 108.341C76.5409 108.962 75.883 109.299 75.2944 109.084L74.474 108.785C73.8855 108.57 73.603 107.888 73.8528 107.286L78.0337 97.2C78.1398 96.9299 78.3462 96.7111 78.6096 96.5893C78.8731 96.4675 79.1735 96.4521 79.448 96.5464Z"
        fill="#5368E5"
      />
      <path
        d="M84.6698 96.9534C84.7631 96.9508 84.856 96.9667 84.9432 97C85.0305 97.0333 85.1103 97.0835 85.1781 97.1477C85.2459 97.2119 85.3004 97.2888 85.3385 97.3741C85.3766 97.4593 85.3975 97.5512 85.4001 97.6446L85.7288 104.744C85.7394 104.946 85.6695 105.145 85.5342 105.295C85.3989 105.446 85.2093 105.537 85.0071 105.548L84.431 105.568C84.3324 105.571 84.2344 105.553 84.1431 105.516C84.0517 105.478 83.9692 105.422 83.9005 105.352C83.8318 105.281 83.7786 105.197 83.7442 105.104C83.7097 105.012 83.6948 104.913 83.7004 104.815L83.9477 97.6951C83.9529 97.5034 84.03 97.3206 84.1638 97.1832C84.2976 97.0457 84.4782 96.9637 84.6698 96.9534Z"
        fill="#5368E5"
      />
      <path
        d="M88.4481 93.9077C88.9382 93.5478 89.6791 93.7494 90.128 94.3646L98.0971 105.289C98.5731 105.941 98.5367 106.803 98.0166 107.185L97.2921 107.717C96.7724 108.099 95.9785 107.846 95.5457 107.162L88.3007 95.7056C87.8934 95.0608 87.9581 94.2676 88.4481 93.9077Z"
        fill="#5368E5"
      />
    </svg>
  )
}

export const Connectivity = styled(Svg)
