import {styled} from '../config'

function Svg(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="11" viewBox="0 0 13 11" fill="none" {...props}>
      <path
        d="M8.98223 0.666669H5.40994C5.30144 0.666985 5.19528 0.637979 5.10494 0.583335L4.46789 0.196044C4.25694 0.0677967 4.00876 -0.000449282 3.75499 2.22593e-06H2.01594C1.67571 0.000333106 1.34952 0.123356 1.10894 0.342076C0.868357 0.560797 0.73304 0.857351 0.732676 1.16667V1.66667H10.2655C10.2655 1.02334 9.68986 0.666669 8.98223 0.666669ZM9.34315 8H1.65502C1.3183 7.99965 0.995228 7.879 0.755467 7.66406C0.515707 7.44913 0.378473 7.15713 0.373362 6.85104L0.0035072 3.41896V3.41313C-0.00860444 3.27557 0.0107317 3.13722 0.0602989 3.00678C0.109866 2.87635 0.188591 2.75666 0.291521 2.65523C0.394451 2.55381 0.519357 2.47285 0.658379 2.41745C0.797402 2.36205 0.947532 2.33341 1.09932 2.33333H9.90114C10.0529 2.33347 10.203 2.36215 10.3419 2.41757C10.4809 2.473 10.6058 2.55397 10.7086 2.65538C10.8115 2.7568 10.8902 2.87648 10.9397 3.00688C10.9893 3.13729 11.0086 3.2756 10.9965 3.41313V3.41896L10.6248 6.85104C10.6197 7.15713 10.4825 7.44913 10.2427 7.66406C10.0029 7.879 9.67986 7.99965 9.34315 8Z"
        fill="black"/>
    </svg>
    // <svg width="13" height="11" viewBox="0 0 13 11" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    //   <path
    //     d="M1.81848 3.86362V2.02272C1.81848 1.75148 1.92623 1.49135 2.11803 1.29955C2.30983 1.10775 2.56996 1 2.8412 1H4.78156C4.9835 1.00001 5.18091 1.05979 5.34892 1.17182L6.06073 1.64636C6.22874 1.75839 6.42615 1.81817 6.62809 1.81818H10.6139C10.8851 1.81818 11.1453 1.92593 11.3371 2.11773C11.5289 2.30952 11.6366 2.56966 11.6366 2.8409V3.86362"
    //     stroke="black" strokeWidth="0.818178" strokeLinecap="round" strokeLinejoin="round"/>
    //   <path
    //     d="M12.452 4.74763L12.0373 8.97787C12.0373 9.2488 11.9298 9.50866 11.7383 9.70041C11.5469 9.89216 11.2873 10.0001 11.0163 10.0006H2.43825C2.16732 10.0001 1.90764 9.89216 1.71623 9.70041C1.52482 9.50866 1.41732 9.2488 1.41732 8.97787L1.0026 4.74763C0.993602 4.63505 1.00801 4.52182 1.04491 4.41508C1.08182 4.30834 1.14043 4.21039 1.21704 4.12741C1.29366 4.04443 1.38663 3.97821 1.49009 3.93292C1.59356 3.88763 1.70528 3.86425 1.81822 3.86426H11.6389C11.7516 3.8646 11.8631 3.88824 11.9662 3.93369C12.0694 3.97913 12.1621 4.0454 12.2384 4.12835C12.3147 4.21129 12.3731 4.30911 12.4099 4.41568C12.4466 4.52225 12.461 4.63526 12.452 4.74763Z"
    //     stroke="black" strokeWidth="0.818178" strokeLinecap="round" strokeLinejoin="round"/>
    // </svg>
  )
}

export const FolderBlack = styled(Svg)
