'use client'
export function Alert(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      {...props}>
      <path
        d="M8.53125 0.96875C3.82717 0.96875 0 4.79592 0 9.5C0 14.2041 3.82717 18.0312 8.53125 18.0312C13.2353 18.0312 17.0625 14.2041 17.0625 9.5C17.0625 4.79592 13.2353 0.96875 8.53125 0.96875ZM8.53125 14.0901C8.36901 14.0901 8.21041 14.0419 8.07551 13.9518C7.94061 13.8617 7.83547 13.7336 7.77338 13.5837C7.71129 13.4338 7.69505 13.2688 7.7267 13.1097C7.75835 12.9506 7.83648 12.8044 7.9512 12.6897C8.06592 12.575 8.21209 12.4968 8.37121 12.4652C8.53034 12.4335 8.69528 12.4498 8.84517 12.5119C8.99506 12.574 9.12318 12.6791 9.21332 12.814C9.30345 12.9489 9.35156 13.1075 9.35156 13.2697C9.35156 13.4873 9.26514 13.696 9.1113 13.8498C8.95746 14.0036 8.74881 14.0901 8.53125 14.0901ZM9.42211 5.83977L9.18668 10.8437C9.18668 11.0177 9.11754 11.1846 8.99447 11.3077C8.8714 11.4308 8.70448 11.4999 8.53043 11.4999C8.35638 11.4999 8.18946 11.4308 8.06639 11.3077C7.94332 11.1846 7.87418 11.0177 7.87418 10.8437L7.63875 5.84223V5.84018C7.63359 5.71997 7.65281 5.59996 7.69525 5.48738C7.7377 5.3748 7.80249 5.27197 7.88572 5.18509C7.96895 5.09821 8.0689 5.02907 8.17956 4.98184C8.29022 4.93461 8.40929 4.91026 8.52961 4.91026C8.64993 4.91026 8.769 4.93461 8.87965 4.98184C8.99031 5.02907 9.09027 5.09821 9.1735 5.18509C9.25673 5.27197 9.32152 5.3748 9.36396 5.48738C9.40641 5.59996 9.42563 5.71997 9.42047 5.84018L9.42211 5.83977Z"
        fill="#EE4562"
      />
    </svg>
  )
}
