'use client'
import { SVGProps } from 'react'

export function Trash(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M4.84442 15.1663L5.67757 15.1108L4.84442 15.1663ZM15.1556 15.1663L15.9887 15.2218L15.1556 15.1663ZM2.5 4.165C2.03884 4.165 1.665 4.53884 1.665 5C1.665 5.46116 2.03884 5.835 2.5 5.835V4.165ZM17.5 5.835C17.9612 5.835 18.335 5.46116 18.335 5C18.335 4.53884 17.9612 4.165 17.5 4.165V5.835ZM9.16833 9.16667C9.16833 8.70551 8.79449 8.33167 8.33333 8.33167C7.87218 8.33167 7.49833 8.70551 7.49833 9.16667H9.16833ZM7.49833 13.3333C7.49833 13.7945 7.87218 14.1683 8.33333 14.1683C8.79449 14.1683 9.16833 13.7945 9.16833 13.3333H7.49833ZM12.5017 9.16667C12.5017 8.70551 12.1278 8.33167 11.6667 8.33167C11.2055 8.33167 10.8317 8.70551 10.8317 9.16667H12.5017ZM10.8317 13.3333C10.8317 13.7945 11.2055 14.1683 11.6667 14.1683C12.1278 14.1683 12.5017 13.7945 12.5017 13.3333H10.8317ZM12.4197 5.20813C12.5346 5.65473 12.9899 5.92359 13.4365 5.80864C13.8831 5.6937 14.1519 5.23847 14.037 4.79187L12.4197 5.20813ZM3.33352 5.05554L4.01127 15.2218L5.67757 15.1108L4.99982 4.94446L3.33352 5.05554ZM7.33888 18.335H12.6611V16.665H7.33888V18.335ZM15.9887 15.2218L16.6665 5.05554L15.0002 4.94446L14.3224 15.1108L15.9887 15.2218ZM15.8333 4.165H4.16667V5.835H15.8333V4.165ZM2.5 5.835H4.16667V4.165H2.5V5.835ZM15.8333 5.835H17.5V4.165H15.8333V5.835ZM12.6611 18.335C14.4169 18.335 15.8719 16.9737 15.9887 15.2218L14.3224 15.1108C14.2641 15.9854 13.5377 16.665 12.6611 16.665V18.335ZM4.01127 15.2218C4.12806 16.9737 5.58313 18.335 7.33888 18.335V16.665C6.46232 16.665 5.73588 15.9854 5.67757 15.1108L4.01127 15.2218ZM7.49833 9.16667V13.3333H9.16833V9.16667H7.49833ZM10.8317 9.16667V13.3333H12.5017V9.16667H10.8317ZM10 3.335C11.163 3.335 12.1423 4.1304 12.4197 5.20813L14.037 4.79187C13.5743 2.99422 11.9434 1.665 10 1.665V3.335ZM7.58034 5.20813C7.85773 4.1304 8.83704 3.335 10 3.335V1.665C8.0566 1.665 6.42574 2.99422 5.96305 4.79187L7.58034 5.20813Z"
        fill="currentColor"
      />
    </svg>
  )
}
