import { styled } from '../config'

function Svg(props) {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="#292929"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <g>
        <rect width="30" height="30" rx="5" fill="#parent" fillOpacity="0.7" />
        <path
          d="M23.7548 21.394L20.1277 17.7669C21.0009 16.6043 21.4723 15.1893 21.4707 13.7354C21.4707 10.0215 18.4492 7 14.7354 7C11.0215 7 8 10.0215 8 13.7354C8 17.4492 11.0215 20.4707 14.7354 20.4707C16.1893 20.4723 17.6043 20.0009 18.7669 19.1277L22.394 22.7548C22.5776 22.9189 22.8171 23.0065 23.0632 22.9996C23.3094 22.9927 23.5436 22.8919 23.7177 22.7177C23.8919 22.5436 23.9927 22.3094 23.9996 22.0632C24.0065 21.8171 23.9189 21.5776 23.7548 21.394ZM9.92439 13.7354C9.92439 12.7838 10.2065 11.8537 10.7352 11.0625C11.2638 10.2714 12.0152 9.65473 12.8943 9.2906C13.7734 8.92647 14.7407 8.8312 15.6739 9.01683C16.6072 9.20246 17.4644 9.66066 18.1372 10.3335C18.8101 11.0063 19.2683 11.8635 19.4539 12.7968C19.6395 13.73 19.5442 14.6973 19.1801 15.5764C18.816 16.4555 18.1994 17.2069 17.4082 17.7355C16.617 18.2642 15.6869 18.5463 14.7354 18.5463C13.4599 18.5448 12.2371 18.0374 11.3352 17.1355C10.4333 16.2336 9.92592 15.0108 9.92439 13.7354Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath>
          <rect width="30" height="30" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export const MagnifyingGlassSolid = styled(Svg)
